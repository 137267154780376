import React, { Fragment, useState, useRef, useEffect } from "react";
import { Row, Col, Card, Button, Radio, Modal, Checkbox, Badge, Input, Select } from "antd";
import { CloseOutlined, SyncOutlined, ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import "react-aspect-ratio/aspect-ratio.css";
import "../../style.css";
import "../../globalStyles.css";
import data from "../../../constants/restaurantPos.json";
import { htmlPrint } from "../../../lib/printer/htmlPrinter";
import { xmlPrint } from "../../../lib/printer/xmlPrinter";
import retailData from "../../../constants/retailPos.json";
import DecrementImg from "../../../assets/images/Decrement2.svg";
import IncrementImg from "../../../assets/images/Increment2.svg";
import TimerIcon from "../../../assets/images/timer-icon.svg";
import MobileIcon from "../../../assets/images/mobileIcon.svg";
import POSIcon from "../../../assets/images/pointofsale.svg";
import _ from "lodash";

import { useHistory } from "react-router-dom";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
// import RefundBtn from "../../../assets/images/refund-btn.svg";
// import PrintBillBtn from "../../../assets/images/print-list.svg";
// import PrintReceiptBtn from "../../../assets/images/print-receipt.svg";
// import ParkedBtn from "../../../assets/images/parked-orders.svg";
import HomeGreen from "../../../assets/images/home-green.svg";
import RefundIcon from "../../../assets/images/refund.svg";
import PrintIcon from "../../../assets/images/print.svg";
import Timer from "../../../assets/images/timer.svg";
import ok from "../../../assets/images/tick.svg";
import Backspace from "../../../assets/images/backspac.png";
import AspectRatio from "react-aspect-ratio";
import TextArea from "rc-textarea";
import Tick from "../../../assets/images/tick-small.svg";

const { Option } = Select;

const PointOfSaleRestaurantModals = (props) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  // const isPrintModeXML = tillData.tillAccess.cwrTill.hardwareController.printReceipt === "Y" ? true : false;
  const {
    displayAddOnSelection,
    handleAddOnModalClose,
    addOnsList,
    selectedProduct,
    selectedAddons,
    handleAddOnValue,
    currencyType,
    selectedProductInCart,
    handleQty,
    handleAdd,
    selectedItemsModalOpen,
    setSelectedItemsModalOpen,
    selectProductInCart,
    handleDisplayOk,
    displayOrderItemsModal,
    orderStatus,
    selectedOrderStatus,
    handleOrderStatusSelection,
    handleOrderStatus,
    setDisplayOrderItemsModal,
    orderDetails,
    handleStatusButton,
    barcodeOrderStatusModal,
    setBarcodeOrderStatusModal,
    barcodeOrderList,
    handleBarcodeOrderPrint,
    handleAccept,
    cart,
    openPaymentModal,
    billConfirmationModalOpen,
    setBillConfirmationModalOpen,
    parkedList,
    openDisplayParkedBillModal,
    managementScreenShow,
    setManagementScreenShow,
    orderTypeSelection,
    manualDiscountModalVisible,
    setManualDiscountInput,
    setManualDiscountModalVisible,
    manualDiscountTypes,
    setSelectedManualDiscountType,
    enableManualDiscountInput,
    manualDiscountInput,
    applyManualDiscount,
    removeAllDiscounts,
    handleManualDiscountKeyPress,
    loyalityOtpModalVisible,
    setLoyalityOtpModalVisible,
    setLoyaltyPaymentOtp,
    processOtpInput,
    loyaltyPaymentOtp,
  } = props;

  const handleBillPrint = () => {
    cart["orderSelection"] = orderTypeSelection;
    htmlPrint(cart, "Receipt");
    setBillConfirmationModalOpen(false);
  };

  // Bill Management Screen Functionalities //

  const history = useHistory();
  const [refundModalShow, setRefundModalShow] = useState(false);
  const [refundConfirmModalShow, setRefundConfirmModalShow] = useState(false);
  const [refundRemarksModalShow, setRefundRemarksModalShow] = useState(false);
  const [keypadInput, setKeypadInput] = useState("");
  const [layout, setLayout] = useState("default");
  const keyboard = useRef();
  const [weight, setWeight] = useState("");

  const handleHome = () => {
    history.push("/pos");
  };

  const onChangeWeight = (event) => {
    setWeight(event.target.value);
  };

  const handleWeight = (value) => {
    // if (weight === "" && value === "x") {
    //   setWeight("");
    // } else if (value === "x") {
    //   setWeight(`${weight.toString().substring(0, weight.toString().length - 1)}`);
    // } else {
    //   setWeight(`${weight}${value}`);
    // }
  };

  const addQuantity = () => {
    // const validation = RegExp("^[0-9]+(.[0-9]{1,2})?$");
    // if (validation.test(parseFloat(weight))) {
    //   const netWeight = parseFloat(weight) - parseFloat(selectedProductInCart.weight);
    //   addProduct(selectedProductInCart, netWeight);
    //   setIsQtyUpdate(false);
    // } else {
    //   console.warn("Invalid input", weight);
    // }
  };

  const handleKeyboardInputs = (value) => {
    setKeypadInput(value);
    console.log("Input changed", value);
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const handleKeyPress = (button) => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleRemarksValue = (event) => {
    const keyboardInput = event.target.value;
    setKeypadInput(keyboardInput);
    keyboard.current.setKeypadInput(keyboardInput);
  };

  return (
    <Fragment>
      {/* Add On Popup Starts */}
      <Modal
        closable={false}
        footer={null}
        bodyStyle={{ padding: 13, background: "#ECECEC", marginTop: "30px" }}
        visible={displayAddOnSelection}
        onCancel={handleAddOnModalClose}
        width={
          (addOnsList?.requiredList?.length > 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 0) ||
          (addOnsList?.requiredList?.length === 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1)
            ? "35%"
            : addOnsList?.requiredList?.length > 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1
            ? "48%"
            : "60%"
        }
      >
        <Row>
          <Col span={24}>
            <p style={data.restaurantPos.content.addOnSelectionModal.title}>{selectedProduct?.name}</p>
          </Col>
        </Row>
        <Scrollbars autoHide className="addOnsModalScroll">
          <Row>
            {addOnsList?.requiredList?.length > 0 ? (
              <Col
                span={addOnsList?.optionsList?.length === 0 ? 24 : Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1 ? 12 : 8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Card bodyStyle={{ padding: 5 }} style={data.restaurantPos.content.addOnSelectionModal.mainCard}>
                  <Row>
                    <Col span={24}>
                      <p style={data.restaurantPos.content.addOnSelectionModal.cardTitle}>{addOnsList?.requiredList[0]?.mAddonGroup?.name || ""}</p>
                    </Col>
                  </Row>
                  <Scrollbars autoHide className="addOnSelectionScroll">
                    <Radio.Group
                      value={_.intersection(addOnsList?.requiredList || [], selectedAddons)?.[0] || {}}
                      onChange={(e) => handleAddOnValue(e, "addOnRadio")}
                      style={{ width: "100%" }}
                    >
                      {addOnsList?.requiredList?.map((item) => (
                        <Card style={data.restaurantPos.content.addOnSelectionModal.addOnCard} bodyStyle={{ padding: 10 }}>
                          <Row>
                            <Col span={24}>
                              <Radio value={item}>
                                {" "}
                                <span style={data.restaurantPos.content.addOnSelectionModal.addOnTitle}>{item.name}</span>
                              </Radio>
                            </Col>
                          </Row>
                        </Card>
                      ))}
                    </Radio.Group>
                  </Scrollbars>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {addOnsList?.optionsList?.length > 0
              ? Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name")).map(([key, value]) => (
                  <Col
                    span={
                      addOnsList?.requiredList?.length === 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1
                        ? 24
                        : (addOnsList?.requiredList?.length > 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1) ||
                          Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 2
                        ? 12
                        : 8
                    }
                    style={{ paddingRight: 10, paddingBottom: 10 }}
                  >
                    <Card bodyStyle={{ padding: 5 }} style={data.restaurantPos.content.addOnSelectionModal.mainCard}>
                      <Row>
                        <Col span={24}>
                          <p style={data.restaurantPos.content.addOnSelectionModal.cardTitle}>{key || ""}</p>
                        </Col>
                      </Row>
                      <Scrollbars autoHide className="addOnSelectionScroll">
                        {value?.map((item, index) => (
                          <Card style={data.restaurantPos.content.addOnSelectionModal.addOnCard} bodyStyle={{ padding: 10 }}>
                            <Row>
                              <Col span={20}>
                                <Checkbox
                                  checked={selectedAddons.findIndex((itemAddOn) => itemAddOn.mProductAddOnId === item.mProductAddOnId) !== -1}
                                  value={item}
                                  onChange={(e) => handleAddOnValue(e, "addOnCheck")}
                                >
                                  {" "}
                                  <span style={data.restaurantPos.content.addOnSelectionModal.addOnTitle}>{item.name}</span>
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <span style={data.restaurantPos.content.addOnSelectionModal.addOnPrice}>
                                {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                  {item.price} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                </span>
                              </Col>
                            </Row>
                          </Card>
                        ))}
                      </Scrollbars>
                    </Card>
                  </Col>
                ))
              : ""}
          </Row>
        </Scrollbars>
        <Row style={{ paddingTop: 15 }}>
          <Col span={12}>
            <Row style={{ paddingTop: 10 }}>
              <Col span={8} style={{ paddingRight: 5 }}>
                <p style={data.restaurantPos.content.addOnSelectionModal.cardTitle}>Quantity</p>
              </Col>
              <Col span={2}>
                <img src={DecrementImg} alt="Decrement" onClick={() => handleQty("minus")} width={20} />
              </Col>
              <Col span={2}>
                <p style={data.restaurantPos.content.addOnSelectionModal.qtyValue}>{selectedProduct?.weight || 1}</p>
              </Col>
              <Col span={3}>
                <img src={IncrementImg} alt="Increment" onClick={() => handleQty("plus")} width={20} />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[8, 0]}>
              <Col span={12}>
                <Button size="large" style={data.restaurantPos.content.orderCart.checkoutBtn} className="addButton" onClick={handleAdd}>
                  <span style={data.restaurantPos.content.orderCart.checkoutBtn.label}>ADD</span>
                </Button>
              </Col>
              <Col span={12}>
                <Button size="large" style={data.restaurantPos.content.addOnSelectionModal.cancelBtn} onClick={handleAddOnModalClose} className="cancelButton">
                  <span style={data.restaurantPos.content.orderCart.checkoutBtn.label}>CANCEL</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
      {/* Add On Popup Ends */}

      {/* Display Order Taking Products Starts */}
      <Modal
        visible={selectedItemsModalOpen}
        closable={false}
        footer={null}
        // width="65%"
        bodyStyle={{ padding: 10 }}
        onCancel={() => setSelectedItemsModalOpen(false)}
      >
        <Row>
          <Col span={12}>
            <p style={data.restaurantPos.content.selectedItemsDisplayModal.title}>Order Items</p>
          </Col>
          <Col span={12}>
            <p style={data.restaurantPos.content.selectedItemsDisplayModal.closeIconP}>
              <CloseOutlined onClick={() => setSelectedItemsModalOpen(false)} style={data.restaurantPos.content.selectedItemsDisplayModal.closeIcon} />
            </p>
          </Col>
        </Row>
        <Card bodyStyle={{ padding: 10 }} style={data.restaurantPos.content.selectedItemsDisplayModal.selectedItemsCard}>
          <Scrollbars autoHide className="selectedItemsScroll">
            {Object.entries(_.groupBy(cart?.items || [], "productCategoryName")).map(([key, value]) => (
              <div style={data.restaurantPos.content.orderCart.thirdRow}>
                <Row>
                  <p style={data.restaurantPos.content.orderCart.productCategory}>{key}</p>
                </Row>
                {value?.map((item, index) => (
                  <div
                    style={{
                      backgroundColor: _.intersectionWith([item], [selectedProductInCart], _.isEqual)?.length > 0 ? "#f1f1f1" : "",
                      padding: "5px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <Row
                      onClick={() => {
                        selectProductInCart(item);
                      }}
                    >
                      <Col span={14}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>{item.name}</p>
                      </Col>
                      <Col span={6}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>
                          <Row>
                            <Col span={10}>
                            {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} {Number(_.sumBy(item?.productAddons, "price") || 0) + item.realPrice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                            </Col>
                            <Col span={5}>X</Col>
                            <Col span={9}>
                              <span style={data.restaurantPos.content.orderCart.productQty}>
                                <span style={data.restaurantPos.content.orderCart.productDetails}>{item.weight}</span>
                              </span>
                            </Col>
                          </Row>
                        </p>
                      </Col>
                      <Col span={4} style={data.restaurantPos.content.orderCart.amtTextAlign}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>
                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                          {item.nettotal} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                        </p>
                      </Col>
                    </Row>
                    {item?.productAddons?.map((subitem) => (
                      <div style={data.restaurantPos.content.orderCart.thirdRow}>
                        <Row>
                          <Col span={10} style={data.restaurantPos.content.orderCart.modifierCol}>
                            <p style={data.restaurantPos.content.orderCart.modifier}>{subitem.name}</p>
                          </Col>
                          <Col
                            span={14}
                            // style={data.restaurantPos.content.orderCart.amtTextAlign}
                          >
                            <p style={data.restaurantPos.content.orderCart.modifierPrice}>+ {subitem.price}</p>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </Scrollbars>
        </Card>
        <Row style={data.restaurantPos.content.selectedItemsDisplayModal.okCancelBtns}>
          <Button
            size="large"
            className="customerDetailsCancelBtn"
            style={data.restaurantPos.content.selectedItemsDisplayModal.selectedItemsCancelBtn}
            onClick={() => setSelectedItemsModalOpen(false)}
          >
            CANCEL
          </Button>
          <Button
            size="large"
            className="customerDetailsSubmitBtnBtn"
            style={data.restaurantPos.content.selectedItemsDisplayModal.selectedItemsSubmitBtn}
            htmlType="submit"
            onClick={handleDisplayOk}
          >
            OK
          </Button>
        </Row>
      </Modal>
      {/* Display Order Taking Products Starts */}

      {/* Kitchen Display Modal Starts */}
      {/* <Modal
        closable={false}
        footer={null}
        bodyStyle={{ padding: 0, background: "#ECECEC" }}
        visible={displayOrderItemsModal}
        style={{ top: 10 }}
        width="90%"
      >
        <Row>
          <Col span={2}>
            <Row>
              {orderStatus?.map((statusItem, index) => (
                <Col
                  span={24}
                  style={
                    statusItem?.title === selectedOrderStatus.title
                      ? data.restaurantPos.content.orderStatusModal.selectedOrderStatusOptionsCol
                      : data.restaurantPos.content.orderStatusModal.orderStatusOptionsCol
                  }
                  onClick={() => handleOrderStatusSelection(statusItem)}
                >
                  <img src={statusItem?.imgSrc} alt={statusItem?.imgSrc} style={{ height: "46px" }} />
                  <h6 style={data.restaurantPos.content.orderStatusModal.statusOptionName}>{statusItem?.title}</h6>
                </Col>
              ))}
            </Row>
          </Col>
          <Col span={22} style={{ padding: 10 }}>
            <Row>
              <Col span={12}>
                <p style={data.restaurantPos.content.orderStatusModal.custTitle}>{tillData.tillAccess.csBunit.name}</p>
              </Col>
              <Col span={11}>
                <p style={data.restaurantPos.content.selectedItemsDisplayModal.closeIconP}>
                  <SyncOutlined onClick={() => handleOrderStatus()} style={data.restaurantPos.content.selectedItemsDisplayModal.closeIcon} />
                </p>
              </Col>
              <Col span={1}>
                <p style={data.restaurantPos.content.selectedItemsDisplayModal.closeIconP}>
                  <CloseOutlined
                    onClick={() => {
                      setDisplayOrderItemsModal(false);
                    }}
                    style={data.restaurantPos.content.selectedItemsDisplayModal.closeIcon}
                  />
                </p>
              </Col>
            </Row>
            <Row>
              <Scrollbars autoHide className="orderCardsScroll">
                <Row>
                  {_.filter(selectedOrderStatus?.title === "Parked" ? [] : orderDetails, (selecteditem) => selecteditem.status === selectedOrderStatus.statusValue)?.map(
                    (item, index) => (
                      <Col span={6} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <Card bodyStyle={{ padding: 10 }} style={data.restaurantPos.content.orderStatusModal.orderItemsCard}>
                          <Row style={{ marginBottom: 10 }}>
                            <Col span={15} style={{ paddingLeft: 5 }}>
                              <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.customerName || "Anonymous"}</p>
                              <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>#{item.orderNo}</p>
                              <div style={data.restaurantPos.content.orderStatusModal.timerDiv}>
                                <img src={TimerIcon} alt="Timer Icon" style={{ paddingRight: 5, width: 15 }} />
                                <span style={data.restaurantPos.content.orderStatusModal.orderTime}>00.02</span>
                              </div>
                            </Col>
                            <Col span={6} style={{ textAlign: "center" }}>
                              <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.dateCreated}</p>
                              <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.noOfItems} Items</p>
                            </Col>
                            <Col span={selectedOrderStatus.title === "All" ? 3 : 2} style={{ textAlign: "center" }}>
                              {item.posOrders === "N" ? (
                                <img src={MobileIcon} alt="Mobile Icon" style={data.restaurantPos.content.orderStatusModal.mobileIcon} />
                              ) : (
                                <img src={POSIcon} alt="POS Icon" style={data.restaurantPos.content.orderStatusModal.mobileIcon} />
                              )}
                            </Col>
                            {selectedOrderStatus.title === "All" ? (
                              <Col span={0} />
                            ) : (
                              <Col span={1} style={data.restaurantPos.content.orderStatusModal.statusCol}>
                                <Badge
                                  color={
                                    item.status === "NE"
                                      ? "#EF8354"
                                      : item.status === "UP"
                                      ? "#D5BD1C"
                                      : item.status === "PR"
                                      ? "#21932A"
                                      : item.status === "DE"
                                      ? "#3ADFC9"
                                      : "#ffffff"
                                  }
                                />
                              </Col>
                            )}
                          </Row>
                          <Scrollbars autoHide className="orderItemsScroll">
                            <div style={data.restaurantPos.content.orderCart.thirdRow}>
                              {item?.lineItems?.map((productItem, index) => (
                                <div
                                  style={{
                                    padding: "5px 0px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Row>
                                    <Col span={1}>
                                      <p style={data.restaurantPos.content.orderCart.productDetails}>{index + 1}</p>
                                    </Col>
                                    <Col span={14}>
                                      <p style={data.restaurantPos.content.orderCart.productDetails}>{productItem.name}</p>
                                    </Col>
                                    <Col span={9}>
                                      <p style={data.restaurantPos.content.orderCart.productDetails}>
                                        <Row>
                                          <Col span={10}>
                                          {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}{" "}
                                            {Number(_.sumBy(productItem?.addOnProducts, "price") || 0) + (item.posOrders === "Y" ? productItem.salePrice : productItem.price)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                          </Col>
                                          <Col span={5}>X</Col>
                                          <Col span={9}>
                                            <span style={data.restaurantPos.content.orderCart.productQty}>
                                              <span style={data.restaurantPos.content.orderCart.productDetails}>
                                                {item.posOrders === "Y" ? productItem.weight : productItem.quantity}
                                              </span>
                                            </span>
                                          </Col>
                                        </Row>
                                      </p>
                                    </Col>
                                  </Row>
                                  {productItem?.addOnProducts?.map((subitem) => (
                                    <div style={data.restaurantPos.content.orderCart.thirdRow}>
                                      <Row>
                                        <Col span={10} style={data.restaurantPos.content.orderStatusModal.orderModifierCol}>
                                          <p style={data.restaurantPos.content.orderCart.modifier}>{subitem.name}</p>
                                        </Col>
                                        <Col
                                          span={14}
                                        >
                                          <p style={data.restaurantPos.content.orderCart.modifierPrice}>+ {subitem.price}</p>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          </Scrollbars>
                          <Row>
                            <Col span={16} />
                            <Col
                              span={8}
                              style={{
                                display: selectedOrderStatus.title === "All" || selectedOrderStatus.title === "Completed" ? "none" : "block",
                              }}
                            >
                              <Button
                                size="medium"
                                style={
                                  item.status === "PR" || item.status === "DE"
                                    ? data.restaurantPos.content.orderStatusModal.statusBtnCompleted
                                    : data.restaurantPos.content.orderStatusModal.statusBtn
                                }
                                className="addButton"
                                onClick={() => handleStatusButton(item)}
                              >
                                <span style={data.restaurantPos.content.orderStatusModal.statusBtn.label}>
                                  {item.status === "NE" ? "Accept" : item.status === "UP" ? "Prepared" : item.status === "PR" ? "Complete" : "Accept"}
                                </span>
                              </Button>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    )
                  )}
                </Row>
              </Scrollbars>
            </Row>
          </Col>
        </Row>
      </Modal> */}
      {/* Kitchen Display Modal Ends */}

      {/* Barcode Order Popup Starts */}
      <Modal
        visible={barcodeOrderStatusModal}
        closable={false}
        footer={null}
        // width="65%"
        bodyStyle={{ padding: 10 }}
        onCancel={() => setBarcodeOrderStatusModal(false)}
      >
        <Row>
          <Col span={12}>
            <p style={data.restaurantPos.content.selectedItemsDisplayModal.title}>Order Details</p>
          </Col>
          <Col span={12}>
            <p style={data.restaurantPos.content.selectedItemsDisplayModal.closeIconP}>
              <CloseOutlined onClick={() => setBarcodeOrderStatusModal(false)} style={data.restaurantPos.content.selectedItemsDisplayModal.closeIcon} />
            </p>
          </Col>
        </Row>
        {barcodeOrderList?.map((item, index) => (
          <Card bodyStyle={{ padding: 10 }} style={data.restaurantPos.content.orderStatusModal.orderItemsCard}>
            <Row style={{ marginBottom: 10 }}>
              <Col span={15} style={{ paddingLeft: 5 }}>
                <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.customerName || "Anonymous"}</p>
                <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>#{item.orderNo}</p>
                <div style={data.restaurantPos.content.orderStatusModal.timerDiv}>
                  <img src={TimerIcon} alt="Timer Icon" style={{ paddingRight: 5, width: 15 }} />
                  <span style={data.restaurantPos.content.orderStatusModal.orderTime}>00.02</span>
                </div>
              </Col>
              <Col span={6} style={{ textAlign: "center" }}>
                <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.dateCreated}</p>
                <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.noOfItems} Items</p>
              </Col>
              <Col span={2} style={{ textAlign: "center" }}>
                {/* <AspectRatio ratio={1}> */}
                <img src={MobileIcon} alt="Mobile Icon" style={data.restaurantPos.content.orderStatusModal.mobileIcon} />
                {/* </AspectRatio> */}
              </Col>
              <Col span={1} style={data.restaurantPos.content.orderStatusModal.statusCol}>
                <Badge color={"#EF8354"} />
              </Col>
            </Row>
            <Scrollbars autoHide className="barcodeSelectedItemsScroll">
              <div style={data.restaurantPos.content.orderCart.thirdRow}>
                {item?.lineItems?.map((productItem, index) => (
                  <div
                    style={{
                      padding: "5px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <Row>
                      <Col span={1}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>{index + 1}</p>
                      </Col>
                      <Col span={14}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>{productItem.name}</p>
                      </Col>
                      <Col span={9}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>
                          <Row>
                            <Col span={10}>
                            {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}{" "}
                              {Number(_.sumBy(productItem?.addOnProducts, "price") || 0) + (item.posOrders === "Y" ? productItem.salePrice : productItem.price)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                            </Col>
                            <Col span={5}>X</Col>
                            <Col span={9}>
                              <span style={data.restaurantPos.content.orderCart.productQty}>
                                <span style={data.restaurantPos.content.orderCart.productDetails}>{item.posOrders === "Y" ? productItem.weight : productItem.quantity}</span>
                              </span>
                            </Col>
                          </Row>
                        </p>
                      </Col>
                    </Row>
                    {productItem?.addOnProducts?.map((subitem) => (
                      <div style={data.restaurantPos.content.orderCart.thirdRow}>
                        <Row>
                          <Col span={10} style={data.restaurantPos.content.orderStatusModal.orderModifierCol}>
                            <p style={data.restaurantPos.content.orderCart.modifier}>{subitem.name}</p>
                          </Col>
                          <Col
                            span={14}
                            // style={data.restaurantPos.content.orderCart.amtTextAlign}
                          >
                            <p style={data.restaurantPos.content.orderCart.modifierPrice}>+ {subitem.price}</p>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </Scrollbars>
            <Row>
              <Col span={12}>
                <Button size="medium" style={data.restaurantPos.content.barcodeOrderStatusModal.printBtn} className="addButton" onClick={() => handleBarcodeOrderPrint(item)}>
                  <span style={data.restaurantPos.content.barcodeOrderStatusModal.printBtn.label}>Print</span>
                </Button>
              </Col>
              <Col span={12}>
                <Button size="medium" style={data.restaurantPos.content.barcodeOrderStatusModal.acceptBtn} className="addButton" onClick={() => handleAccept(item)}>
                  <span style={data.restaurantPos.content.barcodeOrderStatusModal.acceptBtn.label}>Complete</span>
                </Button>
              </Col>
            </Row>
          </Card>
        ))}
      </Modal>
      {/* Barcode Order Popup Starts */}

      {/* BILL TYPE POPUP STARTS */}
      <Modal visible={billConfirmationModalOpen} closable={false} footer={null} bodyStyle={{ padding: 20 }} width="30%" style={{ top: 200 }}>
        <div>
          <Row>
            <Col span={15}>
              <p style={data.restaurantPos.content.billConfirmationModal.title}>Confirm Bill</p>
            </Col>
            <Col span={9}>
              <p style={retailData.posScreenStyles.offerSelectionModal.closeIconP}>
                <CloseOutlined onClick={() => setBillConfirmationModalOpen(false)} style={retailData.posScreenStyles.offerSelectionModal.closeIcon} />
              </p>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col span={12}>
              <Button
                size="medium"
                className="customerDetailsSubmitBtnBtn"
                style={data.restaurantPos.content.billConfirmationModal.billTypeBtn}
                htmlType="submit"
                onClick={handleBillPrint}
              >
                Prepare Bill
              </Button>
            </Col>
            <Col span={12}>
              <Button
                size="medium"
                className="customerDetailsCancelBtn"
                style={data.restaurantPos.content.billConfirmationModal.billTypeBtn}
                onClick={() => {
                  setBillConfirmationModalOpen(false);
                  openPaymentModal();
                }}
              >
                Pay Bill
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      {/* BILL TYPE POPUP ENDS */}

      {/* Management Screen Modal Starts */}
      <Modal visible={managementScreenShow} closable={false} footer={null} bodyStyle={{ padding: 0 }}>
        <div className="managementMainDiv">
          <Row>
            <Col span={12}>{/* <p style={retailData.posScreenStyles.offerSelectionModal.title}>Management Options</p> */}</Col>
            <Col span={12}>
              <p style={retailData.posScreenStyles.offerSelectionModal.closeIconP}>
                <CloseOutlined onClick={() => setManagementScreenShow(false)} style={retailData.posScreenStyles.offerSelectionModal.closeIcon} />
              </p>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <div className="mngBtns" onClick={() => setRefundModalShow(true)}>
                <p style={{ paddingTop: 50 }}>
                  <span className="mngBtnText">Refund</span> <img src={RefundIcon} alt="" className="mngBtnIcon" />
                </p>
              </div>
              <div className="mngBtns">
                <p style={{ paddingTop: 50 }}>
                  <span className="mngBtnText">Print Last Bill</span> <img src={PrintIcon} alt="" className="mngBtnIcon" />
                </p>
              </div>
            </Col>
            <Col span={12}>
              <div className="mngBtns">
                <p style={{ paddingTop: 50 }}>
                  <span className="mngBtnText">Print By Receipt Number</span> <img src={PrintIcon} alt="" className="mngBtnIcon" />
                </p>
              </div>
              <div className="mngBtns" onClick={() => (parkedList?.length > 0 ? openDisplayParkedBillModal() : "")}>
                <p style={{ paddingTop: 50 }}>
                  <span className="mngBtnText">Parked Orders</span>{" "}
                  <Badge
                    count={parkedList?.length || 0}
                    showZero 
                    offset={[0, 20]}
                    size="small"
                    style={{
                      backgroundColor: "#51ad9a",
                      color: "#ddebe8",
                      fontSize: "9px",
                      fontWeight: 600
                    }}
                  >
                    <img src={Timer} alt="" className="mngBtnIcon" />
                  </Badge>{" "}
                  {/* <span style={{ marginTop: 4, float: "right" }}> */}
                  {/* </span> */}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      {/* Management Screen Modal Ends */}

      {/* Receipts Search Number Modal Starts */}
      <Modal visible={refundModalShow} closable={false} footer={null} width="30%" bodyStyle={{ padding: "15px" }} style={{ top: "40px" }}>
        <Row>
          <Col span={16}>
            <p className="enter-receipt">Enter Receipt Number</p>
          </Col>
          <Col span={8} style={{ alignSelf: "center" }}>
            <CloseOutlined style={{ float: "right", fontSize: "16px", marginTop: "-4px" }} onClick={() => setRefundModalShow(false)} />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Input
              size="large"
              placeholder="Search"
              value={weight}
              onChange={onChangeWeight}
              allowClear={false}
              style={retailData.posScreenStyles.quantityUpdateCard.quantityUpdateInput}
              prefix={<SearchOutlined style={retailData.posScreenStyles.customerModal.searchIcon} />}
            />
          </Col>
          <Col span={24}>
            <div>
              <p className="search-results">Search Results</p>
              <p className="receipt-num" onClick={() => setRefundConfirmModalShow(true)}>
                83292301
              </p>
            </div>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={retailData.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("1")}>
                1
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={retailData.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("2")}>
                2
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={retailData.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("3")}>
                3
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button
                style={retailData.posScreenStyles.quantityUpdateCard.quantityBtn}
                // onClick={() => setIsQtyUpdate(false)}
              >
                <CloseOutlined style={{ fontSize: "24px" }} />
                {/* <img src={Aclose} style={retailData.posScreenStyles.quantityUpdateCard.qtyBtnClose} alt="close icon" /> */}
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={retailData.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("4")}>
                4
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={retailData.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("5")}>
                5
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={retailData.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("6")}>
                6
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={retailData.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("x")}>
                <img src={Backspace} alt="back space" style={retailData.posScreenStyles.quantityUpdateCard.qtyBtnBack} />
              </button>
            </AspectRatio>
          </Col>
          <Col span={18}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <AspectRatio ratio="1">
                  <button style={retailData.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("7")}>
                    7
                  </button>
                </AspectRatio>
              </Col>
              <Col span={8}>
                <AspectRatio ratio="1">
                  <button style={retailData.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("8")}>
                    8
                  </button>
                </AspectRatio>
              </Col>
              <Col span={8}>
                <AspectRatio ratio="1">
                  <button style={retailData.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("9")}>
                    9
                  </button>
                </AspectRatio>
              </Col>
              <Col span={16}>
                <button className="zeroBtn" style={retailData.posScreenStyles.quantityUpdateCard.quantityBtnZero} onClick={() => handleWeight("0")}>
                  0
                </button>
              </Col>
              <Col span={8}>
                <AspectRatio ratio="1">
                  <button style={retailData.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight(".")}>
                    00
                  </button>
                </AspectRatio>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row gutter={[16, 16]} style={{ height: "100%" }}>
              <Col span={24}>
                <button style={retailData.posScreenStyles.quantityUpdateCard.quantityBtnOk} onClick={() => addQuantity()}>
                  {/* <AspectRatio ratio="1"> */}
                  <img src={ok} alt="ok" width={40} />
                  {/* </AspectRatio> */}
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
      {/* Receipts Search Number Modal Ends */}

      {/* Refund Confirmation Modal Starts */}
      <Modal visible={refundConfirmModalShow} closable={false} footer={null} width="30%" style={{ top: "200px" }}>
        <Row>
          <Col span={24} style={{ alignSelf: "center" }}>
            <CloseOutlined style={{ float: "right" }} onClick={() => setRefundConfirmModalShow(false)} />
          </Col>
        </Row>
        <div>
          <Row>
            <Col span={24}>
              <p className="refundModalTitle">Are you sure want a Refund?</p>
            </Col>
          </Row>
          <Row className="refundBtns">
            <Button size="medium" className="refundYesBtn" onClick={() => setRefundRemarksModalShow(true)}>
              Yes
            </Button>
            <Button size="medium" className="refundNoBtn" onClick={() => setRefundConfirmModalShow(false)}>
              No
            </Button>
          </Row>
        </div>
      </Modal>
      {/* Refund Confirmation Modal Ends */}

      {/* Refund Remarks Modal Starts */}
      <Modal visible={refundRemarksModalShow} closable={false} footer={null} bodyStyle={{ padding: "15px" }}>
        <Row style={{ marginBottom: 10 }}>
          <Col span={16}>
            <p className="enter-receipt">Enter Receipt Number</p>
          </Col>
          <Col span={8} style={{ alignSelf: "center" }}>
            <CloseOutlined style={{ float: "right", fontSize: "16px", marginTop: "-4px" }} onClick={() => setRefundRemarksModalShow(false)} />
          </Col>
        </Row>
        <p className="receipt-num" onClick={() => setRefundConfirmModalShow(true)}>
          Reason for refund
        </p>
        <TextArea rows={3} value={keypadInput} onChange={handleRemarksValue} placeholder="Type Here" className="refund-remarks" />
        <Keyboard keyboardRef={(r) => (keyboard.current = r)} layoutName={layout} onChange={handleKeyboardInputs} onKeyPress={handleKeyPress} />
      </Modal>
      {/* Refund Remarks Modal Ends */}

      {/* MANUAL DISCOUNT MODAL START */}
      <Modal
        destroyOnClose={true}
        visible={manualDiscountModalVisible}
        closable={false}
        onCancel={() => {
          setManualDiscountInput("");
        }}
        footer={null}
        width="45%"
        bodyStyle={{ padding: 10 }}
      >
        <Row>
          <Col span={12}>
            <p style={retailData.posScreenStyles.productWeightModal.modalTitle}></p>
          </Col>
          <Col span={12}>
            <p style={retailData.posScreenStyles.productWeightModal.closeIconP}>
              <CloseOutlined
                onClick={() => {
                  setManualDiscountModalVisible(false);
                }}
                style={retailData.posScreenStyles.productWeightModal.closeIcon}
              />
            </p>
          </Col>
        </Row>
        <Row gutter={[8, 0]}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <p style={{ marginBottom: "0px" }}>Discount Type</p>
                <Select onChange={setSelectedManualDiscountType} style={{ width: "100%" }}>
                  {manualDiscountTypes.map((md) => (
                    <Option value={md.mPricingrulesId}>{md.name}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p style={{ marginBottom: "0px", marginTop: "12px" }}>Value</p>
                <Input
                  // ref={manualDiscountInputRef}
                  disabled={enableManualDiscountInput}
                  value={manualDiscountInput}
                  onPressEnter={applyManualDiscount}
                  onChange={(e) => setManualDiscountInput(e.target.value)}
                  style={{ width: "100%" }}
                  size="large"
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginBottom: "0px", marginTop: "24px" }}>
                <Button onClick={removeAllDiscounts} style={{ width: "100%" }} danger>
                  Remove Discounts
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Card bodyStyle={{ padding: 15 }}>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleManualDiscountKeyPress("1")}>
                      1
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleManualDiscountKeyPress("2")}>
                      2
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleManualDiscountKeyPress("3")}>
                      3
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleManualDiscountKeyPress("x")}>
                      <img src={Backspace} alt="back space" width={20} height={15} />
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleManualDiscountKeyPress("4")}>
                      4
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleManualDiscountKeyPress("5")}>
                      5
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleManualDiscountKeyPress("6")}>
                      6
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleManualDiscountKeyPress(".")}>
                      .
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleManualDiscountKeyPress("7")}>
                      7
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleManualDiscountKeyPress("8")}>
                      8
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleManualDiscountKeyPress("9")}>
                      9
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleManualDiscountKeyPress("0")}>
                      0
                    </button>
                  </AspectRatio>
                </Col>
                <Button size="large" style={retailData.posScreenStyles.productWeightModal.confirmQtyBtn} onClick={applyManualDiscount}>
                  <img src={Tick} alt="" />
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </Modal>
      {/* MANUAL DISCOUNT MODAL END */}

      {/* LOYALITY OTP MODAL START */}
      <Modal
        visible={loyalityOtpModalVisible}
        closable={true}
        onCancel={() => {
          setLoyalityOtpModalVisible(false);
          setLoyaltyPaymentOtp("");
        }}
        footer={null}
        onclosefooter={null}
        width="35%"
        bodyStyle={{ padding: "20px 15px 15px" }}
      >
        <Row style={{ textAlign: "center" }}>Enter OTP</Row>
        <Row style={{ padding: "30px" }}>
          <Col span={20}>
            <Input onPressEnter={processOtpInput} onChange={(e) => setLoyaltyPaymentOtp(e.target.value)} value={loyaltyPaymentOtp} />
          </Col>
          <Col span={4}>
            <Button onClick={processOtpInput}>Verify</Button>
          </Col>
        </Row>
      </Modal>
      {/* LOYALITY OTP MODAL END */}
    </Fragment>
  );
};

export default PointOfSaleRestaurantModals;
